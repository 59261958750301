import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { styles } from './styles'
import { stylesGrid } from './stylesGrid'
import closeIcon from '../../../assets/images/close.svg'
import { navigate } from 'gatsby'
import { useParams } from '@reach/router'
import IgImageView from '../../IgImageView'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import CHRcard from './card'
import folder from '../../folder'
import { baseURL, ENDPOINTS } from '../../../constants'
import axios from '../../../axios'
import { errorToast } from '../../../../utils/errToast'

// import IOSSwitch from '../../IOSSwitch'
// import BirdAvatar from '../../../assets/images/chirpyest-white.svg'

function array_move(arr: any, old_index: number, new_index: number) {
  const copy = JSON.parse(JSON.stringify(arr))
  copy.splice(new_index, 0, copy.splice(old_index, 1)[0])
  return copy
}

interface FoldersViewProps {
  isFolderView: boolean
  onChange: Function
  shouldShowPublic: boolean
  onChangePublic: Function
  folders: any
  allItems: any
  handleRemoveFolder: Function
  canEdit: boolean
}

const FoldersView = (props: FoldersViewProps) => {
  const {
    isFolderView,
    onChange,
    shouldShowPublic,
    onChangePublic,
    folders,
    allItems,
    handleRemoveFolder,
    canEdit = false,
  } = props

  const [renderedFolders, setRenderedFolders] = useState(folders)

  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  useEffect(() => {
    setRenderedFolders(folders)
  }, [folders])

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    setRenderedFolders(array_move(renderedFolders, dragIndex, hoverIndex))
  }

  const handleUpdatePosition = async (
    id: number,
    currentPosition: number,
    newPosition: any
  ) => {
    try {
      const payload = {
        id,
        currentPosition,
        newPosition,
      }
      if (newPosition !== currentPosition) {
        await axios.post(`${baseURL}${ENDPOINTS.swapFolderPosition}`, payload)
        const resp = await axios.get(
          `${baseURL}${ENDPOINTS.sbFolders.replace(':username', userName)}`
        )
        const sortedFolders = resp.data.data.folders.map((folder: any) => {
          const sortedItems = folder.items.sort(
            (a: any, b: any) => a.position - b.position
          )
          return { ...folder, items: sortedItems }
        })
        setRenderedFolders(sortedFolders)
      }
    } catch (err) {
      errorToast(`couldn't update position, please try again`)
    }
  }

  const classesGrid = stylesGrid()
  const classes = styles()
  const { userName } = useParams()
  const [open, setOpen] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState({
    name: null,
    folderId: null,
  })

  const onRemove = (e: any, folder: any) => {
    e.stopPropagation()
    setOpen(true)
    setSelectedFolder({ ...folder })
  }

  return (
    <div>
      {/* <Grid container justifyContent="flex-end" className={classes.switchBtns}>
        <div>
          <Typography variant="button" component="span">
            folder view
          </Typography>
          <IOSSwitch
            checked={isFolderView}
            onChange={() => onChange(!isFolderView)}
            name="folderView"
          />
        </div>
        <div>
          <Typography variant="button" component="span">
            public folders
          </Typography>
          <IOSSwitch
            checked={shouldShowPublic}
            onChange={() => onChangePublic(!shouldShowPublic)}
            name="publicFolders"
          />
        </div>
      </Grid> */}
      <Typography variant="h4" className={classes.edgeSpaces}>
        all folders
      </Typography>

      <div className={classesGrid.wrapper}>
        <Grid container spacing={isPhone ? 2 : 3}>
          {allItems && allItems.length > 0 && (
            <Grid
              item
              lg={3}
              md={4}
              sm={4}
              xs={12}
              className={classes.gridItem}
              onClick={() => {
                onChange(false)
                history.pushState('', document.title, window.location.pathname)
              }}
            >
              <div>
                {allItems.map(
                  ({ type, data: { url, link, imageUrl, base64 } }) =>
                    type === 'instagram' ? (
                      <IgImageView
                        url={url || link}
                        className={classes.image}
                      />
                    ) : (
                      <img
                        src={imageUrl || base64}
                        alt=""
                        className={classes.image}
                      />
                    )
                )}
              </div>
              <Typography
                variant="h2"
                className={`${classes.edgeSpaces} ${classes.folderName}`}
              >
                all items
              </Typography>
            </Grid>
          )}
          {renderedFolders.map((elGrid: any, i: any) => {
            return (
              <CHRcard
                key={elGrid.folderId}
                index={i}
                id={elGrid.folderId}
                moveCard={moveCard}
                folder={elGrid}
                handleUpdatePosition={handleUpdatePosition}
                canEdit={canEdit}
                onRemove={onRemove}
                userName={userName}
              />
            )
          })}
        </Grid>
      </div>

      {/* <Grid
        container
        alignItems="stretch"
        spacing={2}
        className={classes.mainGrid}
      >
        {allItems && allItems.length > 0 && (
          <Grid
            item
            lg={3}
            md={4}
            sm={4}
            xs={12}
            className={classes.gridItem}
            onClick={() => {
              onChange(false)
              history.pushState('', document.title, window.location.pathname)
            }}
          >
            <div>
              {allItems.map(({ type, data: { url, link, imageUrl, base64 } }) =>
                type === 'instagram' ? (
                  <IgImageView url={url || link} className={classes.image} />
                ) : (
                  <img
                    src={imageUrl || base64}
                    alt=""
                    className={classes.image}
                  />
                )
              )}
            </div>
            <Typography
              variant="h2"
              className={`${classes.edgeSpaces} ${classes.folderName}`}
            >
              all items
            </Typography>
          </Grid>
        )}
        {renderedFolders.map(({ name, items, folderId }) => (
          <Grid
            item
            lg={3}
            md={4}
            sm={4}
            xs={12}
            className={classes.gridItem}
            onClick={() =>
              navigate(
                `/s/${userName}/${name.replace(
                  /\s+|\/|\\|#/g,
                  '-'
                )}/${folderId}`
              )
            }
          >
            <div className={classes.imageWrapper}>
              {[0, 1, 2].map(index =>
                items[index] ? (
                  items[index].type === 'instagram' ? (
                    <IgImageView
                      url={items[index].data.url || items[index].data.link}
                      className={classes.image}
                    />
                  ) : (
                    <img
                      src={
                        items[index].data.imageUrl || items[index].data.base64
                      }
                      alt="product image 2"
                      className={classes.image}
                    />
                  )
                ) : (
                  <div
                    className={classes.image}
                    style={{ backgroundColor: '#F5F6F7' }}
                  />
                )
              )}
            </div>
            <Typography
              variant="h2"
              className={`${classes.edgeSpaces} ${classes.folderName}`}
            >
              {name}
            </Typography>
            {canEdit && (
              <span
                className={classes.deleteButton}
                onClick={e => onRemove(e, { folderId, name })}
              >
                {closeIcon && (
                  <img
                    src={closeIcon}
                    className={classes.deleteIcon}
                    alt="buttonIcon"
                  />
                )}
              </span>
            )}
          </Grid>
        ))}
      </Grid> */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">delete folder</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`are you sure you want to delete ${selectedFolder.name} folder?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleRemoveFolder(selectedFolder.folderId)
              setOpen(false)
            }}
            variant="contained"
            color="secondary"
          >
            yes
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
            autoFocus
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FoldersView
