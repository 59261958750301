import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from '@reach/router'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Typography, Grid } from '@material-ui/core'
import BoardProfile from '../../shoppingBoard/boardProfile'
import ContentContainer from './../../../components/contentContainer'
import GridPaging from '../../shoppingBoard/paging'
import GridItemsStatic from '../../shoppingBoard/gridItemsStatic'
import { useAppState } from '../../../appState'
import { ENDPOINTS, ROUTES } from '../../../constants'
import BoardEmpty from '../../shoppingBoard/boardEmpty'
import axios from '../../../axios'
import _ from 'lodash'
import { styles } from './styles'
import Auth from '@aws-amplify/auth'
import CHRSpinner from '../../spinner'
import CHRButton from '../../button'
import CHRSectionContainer from '../../sectionContainer'
import chirpyestIcon from '../../../assets/images/chirpyest-logo.svg'
import { Link, navigate } from 'gatsby'
import IOSSwitch from '../../IOSSwitch'
import Input from '../../input'
import editIcon from '../../../assets/images/edit-info.svg'
import SharePrivateFolderModal from '../shareModal'
import { TrackingCase } from '../../../../utils/trackingCases'
import { tracker } from '../../../systemLogs'
import { errorToast } from '../../../../utils/errToast'

interface ListingBoardProps {
  t: TFunction
  image: any
}

const ListingBoard = ({ t, image }: ListingBoardProps) => {
  const classes = styles()

  const [appState, dispatch] = useAppState()
  const [defaultSort, setDefaultSort] = useState('position')
  const [shoppingBoardItems, setShoppingBoardItems] = useState([])
  const [shoppingBoardError, setShoppingBoardError] = useState('')
  const [shareLink, setShareLink] = useState('')
  const [items, setItems] = useState([])
  const [canEdit, setEdit] = useState(false)
  const [updatePositionItems, setUpdatePositionItems] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const [folderInfo, setFolderInfo] = useState({
    name: null,
    isPublic: false,
  })
  const nameInput = useRef(null)
  const [isEditingName, setIsEditingName] = useState(false)
  const [folderNameError, setFolderNameError] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [sharePrivateFolderUrl, setSharePrivateFolderUrl] = useState(null)
  const [sharePrivateFolderError, setSharePrivateFolderError] = useState('')

  const params = useParams()
  const query = new URLSearchParams(useLocation().search).get('t')

  const options = [
    { value: 1, label: t('shoppingBoard.trending') },
    { value: 2, label: t('shoppingBoard.price') },
    { value: 3, label: t('shoppingBoard.name') },
    { value: 4, label: t('shoppingBoard.latest') },
    { value: 6, label: t('shoppingBoard.customPosition') },
    { value: 5, label: t('shoppingBoard.cashpack') },
  ]

  const nonUserOptions = [
    { value: 1, label: t('shoppingBoard.trending') },
    { value: 2, label: t('shoppingBoard.price') },
    { value: 3, label: t('shoppingBoard.name') },
    { value: 4, label: t('shoppingBoard.latest') },
    { value: 6, label: t('shoppingBoard.customPosition') },
  ]

  const [selectedSort, setSort] = useState(options[4])

  const [pages, setPages] = useState(0)
  const perPage = 48
  const [selectedPage, setSelectedPage] = useState(1)
  const [isLoadMore, setLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleChangePage = (page: number) => {
    setLoadMore(false)
    setSelectedPage(page)
    window.scroll({
      top: 200,
      behavior: 'auto',
    })
  }

  const handleLoadMore = (page: number) => {
    setSelectedPage(page)
    setLoadMore(true)
  }

  const handleSortChange = (event: any) => {
    setSort(event)
    let newItems = shoppingBoardItems
    switch (event.value) {
      case 1: {
        newItems = _.orderBy(shoppingBoardItems, ['trendingCount'], ['desc'])
        break
      }
      case 2: {
        newItems = _.orderBy(
          shoppingBoardItems,
          item => Number(item.data.price.slice(1)),
          ['desc']
        )
        break
      }
      case 3: {
        newItems = _.orderBy(shoppingBoardItems, ['data.name'], ['asc'])
        break
      }
      case 4: {
        newItems = _.orderBy(shoppingBoardItems, ['id'], ['desc'])
        break
      }
      case 5: {
        newItems = _.orderBy(
          shoppingBoardItems,
          item =>
            Number(item.data.cashBack.slice(0, item.data.cashBack.length - 1)),
          ['desc']
        )
        break
      }
      case 6: {
        newItems = _.orderBy(
          shoppingBoardItems,
          ['folderItemPosition'],
          ['asc']
        )
        break
      }
      default:
        break
    }
    const itemsWithPaging = settingPages(newItems)
    setShoppingBoardItems(itemsWithPaging)
    setSelectedPage(1)
  }

  const handleRemoveItem = async (i: any) => {
    setItems(_.reject(items, { i: i }))
    tracker.track(
      TrackingCase.UserTracking,
      `DELETE ${ENDPOINTS.deleteItemFromFolder}`,
      { location: 'ListingBoard', itemId: i }
    )
    try {
      await axios.delete(
        ENDPOINTS.deleteItemFromFolder.replace(':folderItemId', i)
      )
      const newItems = _.reject(shoppingBoardItems, { folderItemId: i })
      const itemsWithPaging = settingPages(newItems)
      setShoppingBoardItems(itemsWithPaging)
      const oldPage = selectedPage
      const totalPages = _.ceil(itemsWithPaging.length / perPage)
      if (oldPage >= totalPages) {
        setSelectedPage(totalPages)
        setPages(totalPages)
      }
    } catch (error) {
      errorToast(`couldn't remove item, please try again`)
    }
  }

  const settingPages = (items: any) => {
    let newItems: any[] = []
    items.map((item: any, index: number) => {
      const num = index + 1
      const obj = item
      obj.page = _.ceil(num / perPage)
      obj.customPosition = num
      newItems.push({ obj })
    })
    return items
  }

  const [isMoved, setIsMoved] = useState(
    window.localStorage.getItem('movedItems') ?? false
  )

  const handleFolderUpdatePosition = async (
    itemId: number,
    itemPosition: number,
    targetIndex: any
  ) => {
    try {
      const newPosition = shoppingBoardItems[targetIndex].folderItemPosition
      if (newPosition !== itemPosition) {
        await axios.post(
          ENDPOINTS.SBFolderUpdatePosition.replace(
            ':folderId',
            params.folderId
          ),
          {
            id: itemId,
            newPosition: newPosition,
            currentPosition: itemPosition,
          }
        )

        setShoppingBoardItems(s => {
          let items
          if (newPosition > itemPosition) {
            items = shoppingBoardItems.map(item => ({
              ...item,
              folderItemPosition:
                item.folderItemId === itemId
                  ? newPosition
                  : item.folderItemPosition > itemPosition &&
                    item.folderItemPosition <= newPosition
                  ? item.folderItemPosition - 1
                  : item.folderItemPosition,
            }))
          } else {
            items = shoppingBoardItems.map(item => ({
              ...item,
              folderItemPosition:
                item.folderItemId === itemId
                  ? newPosition
                  : item.folderItemPosition >= newPosition &&
                    item.folderItemPosition < itemPosition
                  ? item.folderItemPosition + 1
                  : item.folderItemPosition,
            }))
          }
          return _.orderBy(items, ['folderItemPosition'], ['asc'])
        })
      }
    } catch (err) {
      errorToast(`couldn't update position, please try again`)
    }
  }

  const reloadShopping = async () => {
    try {
      let { folderId } = params
      const endpoint = query
        ? `${ENDPOINTS.getSBFolder.replace(':id', folderId)}?t=${query}`
        : ENDPOINTS.getSBFolder.replace(':id', folderId)
      const currentUserInfo = await Auth.currentCredentials()
      // userId = await currentSession.getIdToken().payload['custom:user_id']
      const {
        data: { data },
      } = await axios.get(`${endpoint}`)
      const sbBoardItems = data || []
      setPages(sbBoardItems < 1 ? 0 : _.ceil(sbBoardItems.length / perPage))
      let trendingItems: any[] = []
      trendingItems = sbBoardItems
        ? _.orderBy(sbBoardItems, ['id'], ['desc'])
        : []
      setSort(options[4])
      const itemsWithPaging = settingPages(trendingItems)
      setShoppingBoardItems(itemsWithPaging)
      setSelectedPage(1)
    } catch (err) {
      // TODO: remove this function since it is not used anywhere or handle error if it is used in the future
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const { userName, folderId } = params
    const endpoint = query
      ? `${ENDPOINTS.getSBFolder.replace(':id', folderId)}?t=${query}`
      : ENDPOINTS.getSBFolder.replace(':id', folderId)
    if (appState.username === userName) {
      setEdit(true)
    } else {
      setEdit(false)
    }
    const getShoppingBoard = async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`${endpoint}`)
        data.folder?.shoppingBoardItems.forEach((element: any) => {
          element['userId'] = data.userInfo?.id
        })
        const sbBoardItems = data.folder?.shoppingBoardItems || []
        setUserInfo(data.userInfo)
        setPages(sbBoardItems < 1 ? 0 : _.ceil(sbBoardItems.length / perPage))
        let trendingItems: any[] = []
        if (defaultSort === 'id') {
          trendingItems = sbBoardItems
            ? _.orderBy(sbBoardItems, ['id'], ['desc'])
            : []
        } else if (isMoved || defaultSort === 'position') {
          setSort(options[4])
          trendingItems = sbBoardItems
            ? _.orderBy(sbBoardItems, ['folderItemPosition'], ['asc'])
            : []
        } else {
          trendingItems = sbBoardItems
            ? _.orderBy(sbBoardItems, ['trendingCount'], ['desc'])
            : []
        }
        const itemsWithPaging = settingPages(trendingItems)
        setShoppingBoardItems(itemsWithPaging)
        setFolderInfo({
          name: data.folder?.name,
          isPublic: data.folder?.isPublic,
        })
        setSelectedPage(1)
        setIsLoading(false)
      } catch (error) {
        if (error.response?.status === 404) {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setShoppingBoardError(t('messages.somethingWentWrong'))
        }
      }
    }

    getShoppingBoard()
  }, [dispatch, params.folderId, appState.username])

  useEffect(() => {
    if (updatePositionItems.length > 0) {
      var newItems = updatePositionItems
      updatePositionItems.map((item: any, index: number) => {
        if (item.status === 0) {
          try {
            ;(async () => {
              newItems[index]['status'] = 1
              setUpdatePositionItems(newItems)
              const {
                data: { data },
              } = await axios.put(ENDPOINTS.updatePositionShoppingBoardItem, {
                data: item.data,
              })
              if (data) {
                newItems[index]['status'] = -1
                setUpdatePositionItems(newItems)
              }
            })().catch(err => {})
          } catch (error) {
            // TODO: unused code, remove or handle error when used
          }
        }
      })
    }
  }, [updatePositionItems])

  const handleUpdateShortLink = (item, shortLink) => {
    setShoppingBoardItems(items => {
      const i = items.findIndex(e => e.id === item.id)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          data: {
            ...items[i].data,
            shortLink,
          },
        },
        ...items.slice(i + 1),
      ]
    })
  }

  const handleSBoardItemsChange = (item, action) => {
    const itemsCount =
      action === 'added'
        ? shoppingBoardItems.length + 1
        : shoppingBoardItems.length - 1
    setPages(itemsCount < 1 ? 0 : _.ceil(itemsCount / perPage))
    setSelectedPage(1)
    setShoppingBoardItems(pre => {
      const sbBoardItems = JSON.parse(JSON.stringify(pre))
      let trendingItems: any[] = []

      if (action === 'added') {
        sbBoardItems.push(item)
      } else {
        const index = sbBoardItems.findIndex(e => e.data.link === item.link)
        if (index > -1) {
          sbBoardItems.splice(index, 1)
        }
      }

      if (defaultSort === 'id') {
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['id'], ['desc'])
          : []
      } else if (isMoved || defaultSort === 'position') {
        setSort(options[4])
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['position'], ['asc'])
          : []
      } else {
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['trendingCount'], ['desc'])
          : []
      }
      const itemsWithPaging = settingPages(trendingItems)
      return itemsWithPaging
    })
  }

  const handlePublicChange = async () => {
    try {
      setFolderInfo({ ...folderInfo, isPublic: !folderInfo.isPublic })
      await axios.patch(ENDPOINTS.SBFolder.replace(':id', params.folderId), {
        isPublic: !folderInfo.isPublic,
      })
    } catch (err) {
      setTimeout(
        () => setFolderInfo(s => ({ name: s.name, isPublic: !s.isPublic })),
        500
      )
    }
  }

  const focusNameInput = () => {
    setIsEditingName(true)
    nameInput.current.focus()
  }

  const updateFolderName = async () => {
    try {
      await axios.patch(ENDPOINTS.SBFolder.replace(':id', params.folderId), {
        name: folderInfo.name,
      })
    } catch (err) {
      setFolderNameError(t('messages.somethingWentWrong'))
      setTimeout(() => setFolderNameError(''), 1000)
    }
  }

  const closeModal = () => {
    setIsModal(false)
    setSharePrivateFolderError('')
  }

  const showShareUrl = async () => {
    try {
      setIsModal(true)
      let shareUrl = `${process.env.GATSBY_SITE_URL}/s/${
        params.userName
      }/${folderInfo.name.replace(/\s+|\/|\\|#/g, '-')}/${params.folderId}`
      if (!folderInfo.isPublic) {
        tracker.track(
          TrackingCase.UserTracking,
          `POST ${ENDPOINTS.setFolderPrivateToken}`,
          { location: 'ListingBoard', folderId: params.folderId }
        )
        const {
          data: {
            data: { token },
          },
        } = await axios.post(
          ENDPOINTS.setFolderPrivateToken.replace(':id', params.folderId)
        )

        shareUrl += `?t=${token}`
      }
      setSharePrivateFolderUrl(shareUrl)
    } catch (err) {
      setSharePrivateFolderError(t('messages.somethingWentWrong'))
    }
  }

  if (isLoading) {
    return <CHRSpinner />
  }

  if (shoppingBoardError || _.isEmpty(userInfo)) {
    return (
      <CHRSectionContainer>
        <ContentContainer>
          <div className={classes.container404}>
            <img
              src={chirpyestIcon}
              alt={t('shared.chirpyest')}
              className={classes.logo}
            />
            <Typography variant="h1" align="center">
              {shoppingBoardError ? shoppingBoardError : t('notFound.title')}
            </Typography>
            <CHRButton
              label={t('notFound.buttonText')}
              onClick={() => navigate(ROUTES.home)}
              customStyle={classes.button404}
            />
          </div>
        </ContentContainer>
      </CHRSectionContainer>
    )
  }

  if (!folderInfo?.isPublic && !canEdit && !folderInfo.name)
    return (
      <>
        <BoardProfile
          handleSortChange={handleSortChange}
          options={options}
          nonUserOptions={nonUserOptions}
          selectedSort={selectedSort}
          shareUrl={shareLink}
          canEdit={canEdit}
          reloadShopping={reloadShopping}
          shoppingUserProfile={{
            ...userInfo,
            imageKey: userInfo.image,
            imageUrl: '',
          }}
          handleSBoardItemsChange={handleSBoardItemsChange}
        />
        <div className={classes.privateWrapper}>
          <Typography variant="h4">sorry, this board is private</Typography>
          <Typography variant="h3">
            you can view their{' '}
            <Link to={`/s/${params.userName}`} className={classes.shoppingLink}>
              other shopping boards here
            </Link>{' '}
            though!
          </Typography>
        </div>
      </>
    )

  return (
    <>
      <BoardProfile
        handleSortChange={handleSortChange}
        options={options}
        nonUserOptions={nonUserOptions}
        selectedSort={selectedSort}
        shareUrl={shareLink}
        canEdit={canEdit}
        reloadShopping={reloadShopping}
        shoppingUserProfile={{
          ...userInfo,
          imageKey: userInfo.image,
          imageUrl: '',
        }}
        handleSBoardItemsChange={handleSBoardItemsChange}
      />
      <ContentContainer customStyle={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.summaryItems}>
            {shoppingBoardItems && shoppingBoardItems.length > 0 && (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.subGrid}
                >
                  {!canEdit && (
                    <Typography variant="h4">{folderInfo.name}</Typography>
                  )}
                  <div>
                    {canEdit && (
                      <div className={classes.inputNameWrapper}>
                        <Input
                          type="text"
                          // placeholder={t('shoppingBoard.addShortBio')}
                          value={folderInfo.name}
                          customStyles={`${classes.nameInput} ${
                            isEditingName ? classes.nameInputShow : ''
                          }`}
                          elementRef={nameInput}
                          onChange={(event: any) => {
                            setFolderInfo({
                              ...folderInfo,
                              name: event.target.value,
                            })
                          }}
                          onBlur={async () => {
                            if (!folderInfo.name)
                              setFolderNameError('name is required')
                            else {
                              setFolderNameError('')
                              setIsEditingName(false)
                              await updateFolderName() // TODO: API request
                            }
                          }}
                        />

                        <div
                          className={`${classes.nameText} ${
                            isEditingName ? classes.nameTexthHide : ''
                          }`}
                        >
                          {folderInfo.name.length > 34
                            ? folderInfo.name.slice(0, 34) + ' ...'
                            : folderInfo.name}
                        </div>
                        {folderInfo.name && (
                          <>
                            <button
                              type="button"
                              className={`${classes.btnEdit} ${
                                isEditingName ? classes.btnEditHide : ''
                              }`}
                              onClick={focusNameInput}
                            >
                              {editIcon && (
                                <img
                                  src={editIcon}
                                  className={classes.btnIcon}
                                  alt="buttonIcon"
                                />
                              )}
                            </button>
                          </>
                        )}
                      </div>
                    )}
                    {folderNameError && (
                      <Typography
                        variant="button"
                        className={classes.nameValidation}
                      >
                        {folderNameError}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.subBtns}>
                    <CHRButton
                      label="back to all folders"
                      onClick={() => navigate(`/s/${params.userName}#folders`)}
                      customStyle={classes.btn}
                      hoverEffects={true}
                    />
                    {canEdit && (
                      <CHRButton
                        label="share the folder"
                        onClick={showShareUrl}
                        customStyle={`${classes.btn} ${classes.btnOther}`}
                        hoverEffects={true}
                      />
                    )}
                  </div>
                </Grid>
                {canEdit && (
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={{ marginBottom: 15 }}
                  >
                    <div>
                      <Typography variant="button" component="span">
                        public folder
                      </Typography>
                      <IOSSwitch
                        checked={folderInfo.isPublic}
                        onChange={handlePublicChange}
                        name="publicFolders"
                      />
                    </div>
                  </Grid>
                )}
                <Typography component="p" className={classes.perItems}>
                  {shoppingBoardItems.length} item
                  {shoppingBoardItems.length === 1 ? '' : 's'}
                </Typography>
                <GridItemsStatic
                  shoppingBoardItems={shoppingBoardItems}
                  handleRemoveItem={handleRemoveItem}
                  handleUpdateShortLink={handleUpdateShortLink}
                  handleUpdatePosition={handleFolderUpdatePosition}
                  canEdit={canEdit}
                  selectedPage={selectedPage}
                  isLoadMore={isLoadMore}
                  selectedSort={selectedSort}
                  isFolder={true}
                />
                <GridPaging
                  onChange={handleChangePage}
                  handleLoadMore={handleLoadMore}
                  selected={selectedPage}
                  pages={pages}
                />
              </>
            )}
            {((shoppingBoardItems && shoppingBoardItems.length < 1) ||
              shoppingBoardItems === null) &&
              (canEdit ? (
                <BoardEmpty hasUsername={!!appState.username} />
              ) : (
                <Typography variant="h3">
                  {t('shoppingBoard.noProducts')}
                </Typography>
              ))}
          </div>
        </div>
        <SharePrivateFolderModal
          isPublic={folderInfo.isPublic}
          openStatus={isModal}
          handleClose={closeModal}
          shareUrl={sharePrivateFolderUrl}
          error={sharePrivateFolderError}
        />
      </ContentContainer>
    </>
  )
}
export default withTranslation()(ListingBoard)
