import React, { useRef, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Typography from '@material-ui/core/Typography'
// import CHRButton from '../../button'
import CHROutlinedButton from '../../outlinedButton'
import CHRInput from '../../input'
import { useAppState } from '../../../appState'
import { COLORS, ENDPOINTS } from '../../../constants'
import { CircularProgress } from '@material-ui/core'
import InstagramMedia from '../instagramMedia'
import _ from 'lodash'
import axios from '../../../axios'
import { TrackingCase } from '../../../../utils/trackingCases'
import { tracker } from '../../../systemLogs'
import { Storage } from '@aws-amplify/storage'
import { v4 as uuidv4 } from 'uuid'

interface AddFromInstagramModalProps {
  handleClose: any
  openStatus: boolean
  loading: boolean
  handleSBoardItemsChange: Function
  t: TFunction
  hasNextPage: boolean
  sentryRef: any
  rootRef: any
  shoppingBoardItems: any
  isIgPrivate: boolean
  handleSwitchAccount: Function
  error: string
}

const AddFromInstagramModal = ({
  handleClose,
  openStatus,
  loading = true,
  handleSBoardItemsChange,
  t,
  hasNextPage,
  sentryRef,
  rootRef,
  shoppingBoardItems,
  isIgPrivate,
  handleSwitchAccount,
  error,
}: AddFromInstagramModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const [appState, dispatch] = useAppState()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const textAreaRef = useRef(null)
  const [initItems, setInitItems] = useState([])
  const [igUrl, setIgUrl] = useState('')
  const [igUrlPost, setIgUrlPost] = useState(null)
  const [notFoundsMsg, setNotFoundMsg] = useState('')
  const [localLoading, setLoading] = useState(loading)
  const [allItems, setAllItems] = useState(null)
  const [nextIgUrl, setNextUrl] = useState(null)
  const [shoppingBoardItemsIds, setShoppingBoardItemsIds] = useState(new Map())
  const [addFromIgError, setAddFromIgError] = useState('')
  const [igError, setIgError] = useState('')

  const MAX_IG_API_RATE = 10
  const IG_BASE_URL = 'https://www.instagram.com'

  function getImageExtension(url: string) {
    const regex = /\/([^\/?#]+)\.([a-zA-Z0-9]+)(?:[\?#]|$)/
    const match = url.match(regex)
    return match ? match[2] : null
  }

  async function getImageBase64(url: string) {
    const response = await fetch(url)
    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const addToShoppingBoard = async (item: any) => {
    tracker.track(
      TrackingCase.UserTracking,
      `POST ${ENDPOINTS.addToShoppingBoard}`,
      {
        location: 'AddFromInstagramModal',
        link: item.permalink,
        postId: item.id,
      }
    )

    // const profileInfo = await axios.get(
    //   ENDPOINTS.getInstagramUserPic.replace('username', item.username)
    // )

    // console.log(profileInfo?.data?.data)
    // let picUrl = profileInfo?.data?.data.split('profile_pic_url":"')[1]
    // picUrl = picUrl.split('","show_suggested_profiles')[0]
    // picUrl = picUrl.replace(/\\\//g, '/')

    const response = await fetch(item.media_url)
    const blob = await response.blob()

    const ext = getImageExtension(item.media_url) || 'jpg'
    const randomName = `${uuidv4()}.${ext}`
    const result = await Storage.put(randomName, blob, {
      level: 'public',
      customPrefix: { public: 'shopping-board/' },
      contentType: blob.type,
    })

    const url: any = result?.key
      ? `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/shopping-board/${result.key}`
      : undefined

    const res = await axios.post(ENDPOINTS.addToShoppingBoard, {
      type: 'instagram',
      data: {
        // profile_image: profileInfo?.data?.data || '',
        profile_image: '',
        link: item.permalink,
        post_id: item.id,
        username: item.username,
        user_id: appState.instagramInfo.userId,
        url,
      },
    })

    const i = initItems.findIndex(e => e.id === item.id)
    const initInstagram = [
      ...initItems.slice(0, i),
      {
        ...initItems[i],
        shoppingBoardId: res.data.data.id,
      },
      ...initItems.slice(i + 1),
    ]

    dispatch({
      type: 'UPDATE_INSTAGRAM_INFOMATION',
      instagramInfo: {
        ...appState.instagramInfo,
        items: initInstagram,
      },
    })
    handleSBoardItemsChange(res.data.data, 'added')
    if (item.id === igUrlPost?.id) {
      setIgUrlPost({ ...igUrlPost, shoppingBoardId: res.data.data.id })
    }
  }

  const removeFromShoppingBoard = async item => {
    tracker.track(
      TrackingCase.UserTracking,
      `DELETE ${ENDPOINTS.deleteShoppingBoardItem}`,
      { location: 'ListingBoard', itemId: item.shoppingBoardId }
    )
    await axios.delete(
      ENDPOINTS.deleteShoppingBoardItem.replace(':id', item.shoppingBoardId)
    )

    const i = initItems.findIndex(e => e.id === item.id)
    const initInstagram = [
      ...initItems.slice(0, i),
      {
        ...initItems[i],
        shoppingBoardId: null,
      },
      ...initItems.slice(i + 1),
    ]

    dispatch({
      type: 'UPDATE_INSTAGRAM_INFOMATION',
      instagramInfo: {
        ...appState.instagramInfo,
        items: initInstagram,
      },
    })
    handleSBoardItemsChange(item, 'removed')
    if (item.id === igUrlPost?.id) {
      setIgUrlPost({ ...igUrlPost, shoppingBoardId: null })
    }
  }

  const toggleAddToShoppingBoard = async item => {
    try {
      if (item.shoppingBoardId) {
        await removeFromShoppingBoard(item)
      } else {
        await addToShoppingBoard(item)
      }
    } catch (err) {
      setAddFromIgError(t('messages.somethingWentWrong'))
    }
  }

  const handleIgUrlChange = async (event: any) => {
    try {
      const url = event.target.value.trim().replace(/\/$/, '')

      setIgUrl(url)

      if (url.includes(IG_BASE_URL)) {
        if (allItems) {
          let item = allItems.find(e => e.permalink.replace(/\/$/, '') === url)
          if (item) {
            setIgUrlPost(item)
            setNotFoundMsg('')
          } else if (nextIgUrl) {
            const items: any = []
            let i = 0
            let nextUrl = nextIgUrl

            while (nextUrl && i < MAX_IG_API_RATE) {
              const {
                data: { paging, data },
              } = await axios.get(nextUrl)

              data.forEach(e => {
                if (shoppingBoardItemsIds.has(e.id)) {
                  e.shoppingBoardId = shoppingBoardItemsIds.get(e.id)
                }
              })

              items.push(...data)
              nextUrl = paging?.next
              i += 1

              item = items.find(e => e.permalink.replace(/\/$/, '') === igUrl)

              if (item) {
                i = MAX_IG_API_RATE // this will break the loop
              }
            }

            if (item) {
              setNotFoundMsg('')
            } else {
              setNotFoundMsg(t('shoppingBoard.igPostNotFound'))
            }

            setIgUrlPost(item)
            setAllItems(pre => [...pre, ...items])
            setNextUrl(nextUrl)
          } else {
            setIgUrlPost(null)
            setNotFoundMsg(t('shoppingBoard.igPostNotFound'))
          }
        }
      } else {
        setIgUrlPost(null)
        setNotFoundMsg(t('shoppingBoard.igPostNotFound'))
      }
    } catch (err) {
      setIgError(t('messages.somethingWentWrong'))
    }
  }

  useEffect(() => {
    if (_.size(appState.instagramInfo.items) >= appState.instagramInfo.total) {
      setLoading(false)
    }
    setInitItems(appState.instagramInfo.items)
  }, [appState])

  useEffect(() => {
    ;(async () => {
      try {
        const igIgUrl = igUrl && !allItems && igUrl.includes(IG_BASE_URL)

        if (igIgUrl) {
          const igToken = JSON.parse(window.localStorage.getItem('ig_token'))
          const items: any = []
          const shoppingBoardItemsIds = new Map()
          let i = 0
          let nextUrl = `https://graph.instagram.com/me/media?fields=id,username,media_url,permalink&access_token=${igToken.accessToken}&limit=100`
          let item = null

          shoppingBoardItems.forEach(e => {
            if (e.type === 'instagram') {
              shoppingBoardItemsIds.set(e.data.post_id, e.id)
            }
          })

          setShoppingBoardItemsIds(shoppingBoardItemsIds)

          while (nextUrl && i < MAX_IG_API_RATE) {
            const {
              data: { paging, data },
            } = await axios.get(nextUrl)

            data.forEach(e => {
              if (shoppingBoardItemsIds.has(e.id)) {
                e.shoppingBoardId = shoppingBoardItemsIds.get(e.id)
              }
            })

            items.push(...data)
            nextUrl = paging?.next
            i += 1

            item = items.find(e => e.permalink.replace(/\/$/, '') === igUrl)

            if (item) {
              i = MAX_IG_API_RATE // this will break the loop
            }
          }

          if (item) {
            setNotFoundMsg('')
          } else {
            setNotFoundMsg(t('shoppingBoard.igPostNotFound'))
          }

          setIgUrlPost(item)
          setAllItems(items)
          setNextUrl(nextUrl)
        }
      } catch (err) {
        // TODO: handle error
      }
    })()
  }, [igUrl, allItems])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={() => {
          setIgUrl('')
          setAddFromIgError('')
          handleClose()
        }}
        aria-labelledby="shareShoppingBoard"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <div className={classes.container}>
            {localLoading && (
              <div className={classes.loading}>
                <CircularProgress style={{ color: COLORS.black }} size={40} />
              </div>
            )}
            <div
              style={{
                borderBottom: `1px solid ${COLORS.black}`,
                paddingBottom: 11,
              }}
            >
              <Typography variant="h2" component="h2" className={classes.title}>
                {t('shoppingBoard.addtoInstagramTitle')}
              </Typography>
              <CHROutlinedButton
                label="switch accounts"
                onClick={handleSwitchAccount}
              />
            </div>
            {addFromIgError && (
              <p className={classes.message}>{addFromIgError}</p>
            )}
            <div className={classes.gridWrapper}>
              {igError && <p className={classes.message}>{igError}</p>}
              {igUrl ? (
                <ul className={classes.gridItems}>
                  {igUrlPost && (
                    <li
                      key={igUrlPost.shoppingBoardId}
                      className={classes.gridItem}
                      onClick={() => {
                        toggleAddToShoppingBoard(igUrlPost)
                      }}
                    >
                      <div
                        className={`dot ${classes.dot} ${
                          igUrlPost.shoppingBoardId ? 'dotted' : ''
                        }`}
                      ></div>
                      <InstagramMedia item={igUrlPost} />
                    </li>
                  )}
                  {notFoundsMsg && (
                    <li>
                      <p>{notFoundsMsg}</p>
                    </li>
                  )}
                </ul>
              ) : (
                <>
                  <ul ref={rootRef} className={classes.gridItems}>
                    {isIgPrivate && !loading && (
                      <li>
                        <p>{t('shoppingBoard.privateAccount')}</p>
                      </li>
                    )}
                    {!isIgPrivate &&
                      !loading &&
                      initItems &&
                      initItems.map((item: any, index: number) => (
                        <li
                          className={classes.gridItem}
                          onClick={() => {
                            toggleAddToShoppingBoard(item)
                          }}
                        >
                          <div
                            className={`dot ${classes.dot} ${
                              item.shoppingBoardId ? 'dotted' : ''
                            }`}
                          ></div>
                          <InstagramMedia item={item} />
                        </li>
                      ))}
                    {(loading || (hasNextPage && !isIgPrivate && !error)) && (
                      <li ref={sentryRef} style={{ margin: 'auto' }}>
                        <CircularProgress
                          style={{ color: COLORS.black }}
                          size={20}
                        />
                      </li>
                    )}
                  </ul>
                  {error && (
                    <Typography component="p" className={classes.message}>
                      {error}
                    </Typography>
                  )}
                </>
              )}
              <div className={classes.line}></div>
            </div>
            {!isIgPrivate && initItems?.length > 0 && (
              <>
                <Typography component="p" className={classes.des}>
                  {t('shoppingBoard.addtoInstagramOr')}
                </Typography>
                <div className={classes.shareBox}>
                  <div className={classes.formShare}>
                    <div
                      className={`${classes.inputContainer} ${classes.convertedLink}`}
                    >
                      <CHRInput
                        placeholder={t(
                          'shoppingBoard.addtoInstagramPlaceholder'
                        )}
                        value={igUrl}
                        elementRef={textAreaRef}
                        customStyles={classes.input}
                        onChange={handleIgUrlChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </Dialog>
    </>
  )
}

export default withTranslation()(AddFromInstagramModal)
